import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Accordion,
  Card,
  Button,
  CardDeck,
} from "react-bootstrap"
import stressPhoto from "../../../images/christian-erfurt-sxqz2vfofbe-unsplash.jpg"
import PageHeader from "../../../components/page-header"
import Img from "gatsby-image"
import ContactForm from "../../../components/contact-us-form"
import PracticeAreas from "../../../components/practice-areas"

export default ({ data }) => (
  <Layout style={{ color: `teal` }}>
    <Helmet title="Bankruptcy | RWK Attorneys" defer={false} />

    <PageHeader>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/practice-areas">Practice Areas</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Bankruptcy
              </li>
            </ol>
          </nav>
          <h1>
            Bankruptcy
            <br />
            <small>Practice Area</small>
          </h1>
        </Col>
      </Row>
    </PageHeader>

    <Container className="my-5">
      <Row>
        <Col md="3">
          <PracticeAreas />

          <ContactForm />
        </Col>
        <Col className="mt-5 mt-sm-0">
          <Row className="text-center text-sm-left">
            <Col>
              <h1>BANKRUPTCY &amp; CONSUMER LAW</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Russo, White &amp; Keller, P.C. handles several types of
                bankruptcy cases and consumer litigation cases. Bankruptcy is
                never an easy decision and sometimes it is the right decision.
                The bankruptcy laws can be challenging to navigate but they are
                designed to protect you and your property. We help individuals
                and businesses file bankruptcy, consolidate debt, stop creditor
                harassment, and fight to protect our clients’ rights under both
                bankruptcy and consumer laws.
              </p>

              <h2 className="mt-5 text-center">
                Tired of being harassed by creditors?
              </h2>

              <p>
                Are they calling you every hour on the hour every day? Creditors
                seem to be relentless in their quest to force you to pay a debt.
                Often times, the original creditor will sell your debt to debt
                collectors that will harrass you in order to get you to pay
                them. Pressure from both creditors and debt collection agencies
                can come in the form of creditor harassment. We have several
                options at our disposal to put a stop to the harassment.
              </p>

              <CardDeck>
                <Card>
                  <Card.Img
                    variant="top"
                    as={Img}
                    fluid={data.personal.childImageSharp.fluid}
                  />
                  <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-light">
                    <Card.Title>Consumer Bankruptcy</Card.Title>
                    <Card.Text className="d-none">
                      Debt reorganization options for individuals
                    </Card.Text>
                    <Button
                      variant="secondary"
                      className="stretched-link"
                      as={Link}
                      to="/practice-areas/bankruptcy/consumer"
                    >
                      Learn More
                    </Button>
                  </Card.ImgOverlay>
                </Card>
                <Card>
                  <Card.Img
                    variant="top"
                    as={Img}
                    fluid={data.business.childImageSharp.fluid}
                  />
                  <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-light">
                    <Card.Title>Business Bankruptcy</Card.Title>
                    <Card.Text className="d-none">
                      Debt reorganization options for businesses
                    </Card.Text>
                    <Button
                      variant="secondary"
                      className="stretched-link"
                      as={Link}
                      to="/practice-areas/bankruptcy/business"
                    >
                      Learn More
                    </Button>
                  </Card.ImgOverlay>
                </Card>
              </CardDeck>

              <p>
                A wrongful foreclosure action typically occurs when the lender
                starts a non judicial foreclosure action when it simply has no
                legal cause. Wrongful foreclosure actions are also brought when
                the service providers accept partial payments after initiation
                of the wrongful foreclosure process, and then continue on with
                the foreclosure process. These predatory lending strategies, as
                well as other forms of misleading homeowners, are illegal.
              </p>
              <p>
                Please contact us immediately if you currently have a
                modification of your mortgage pending, and your home has been
                foreclosed (or the mortgage company is threatening foreclosure).
              </p>

              <h2 className="mt-5">
                AUTOMATIC STAY AND DISCHARGE INJUNCTION VIOLATIONS
              </h2>
              <p>
                Filing for bankruptcy creates an automatic stay which is like a
                security blanket or shield that protects you from harassment by
                creditors and stops most non-criminal proceedings against you,
                such as lawsuits and garnishments. It also prevents creditors
                from contacting you by telephone, email, text, or in writing.
                The automatic stay applies while you are in the case.
              </p>
              <p>
                Once you receive a discharge at the end of your case, the
                discharge follows you the rest of your life and protects you
                from continued efforts by creditors or collection agencies from
                trying to collect from you the debt you discharged in the
                bankruptcy.{" "}
              </p>
              <p>
                Sometimes creditors or collection agencies will try to collect
                debt during the bankruptcy case or after the discharge. You have
                certain rights and remedies available to you against those
                creditors or collection agencies when this happens. For example,
                creditors may continue to write or email you and demand payment
                for the debt even though the debt is included in the bankruptcy.
                Sometimes, these actions can occur many months or years after
                you have received a discharge of the debt.{" "}
              </p>
              <p>
                We are one of only a few law firms in Alabama that handle these
                claims and have had great success in protecting the rights of
                debtors. If you feel that your rights might be violated, contact
                us for a free consultation.
              </p>

              <h2 className="mt-5">FAIR DEBT COLLECTION PRACTICES ACT</h2>
              <p>
                The Fair Debt Collection Practices Act (FDCPA) requires debt
                collector to follow certain requirements as they try to collect
                debts. In essence, it provides debtors a large amount of
                protections from others trying to collect debts. For example,
                debt collectors can only contact consumers during certain hours
                of the day, cannot misrepresent the character, amount or legal
                status of the debt, or harass, oppress, or abuse a consumer in
                connection with the collection of the debt.
              </p>
              <p>
                The FDCPA provides protections for consumers when debt
                collectors do not comply with its requirements. If you feel that
                your rights might be violated, contact us for a free
                consultation.
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              <h2>FAQ</h2>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      What is a discharge?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      A discharge is a court order which prohibits the
                      collection of all debt which you listed in your bankruptcy
                      petition. The discharge follows and protects you the rest
                      of your life. There are certain debts that are excepted
                      from discharge and that you still must pay despite a
                      discharge in your bankruptcy case. Three of the most
                      common debts that you still must pay after a discharge are
                      federal and state income taxes, student loans, and child
                      support and alimony. Another type of debt which you must
                      repay despite receiving a discharge is debt that you have
                      reaffirmed, such as a house or car.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Will I be able to keep my house or car and other property?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      In general, the answer is yes. Chapter 7 bankruptcy is
                      designed to wipe out your debt. However, this is
                      conditioned upon you not owning any property that is not
                      exempt. Non-exempt property is sold by the bankruptcy
                      court, converted to cash, and then used to pay back all or
                      a portion of the debt that is owed by you. The bankruptcy
                      law allows you to exempt your property, whether it be a
                      home, car, 401(k), or other property. If your property is
                      exempt, it generally cannot be used to pay your creditors
                      any money. In most cases, all of your property will be
                      exempt. The exemptions differ for each piece of property
                      that you own, and we will be able to explain to you in
                      more detail about these exemptions.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      Will I be able to get rid of property I no longer want or
                      can afford?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      In general, the answer is yes. Some debtors can no longer
                      afford their house or car payments. In other cases, the
                      house is just too big for them to keep up and they want to
                      downsize. In still other cases, a car may keep breaking
                      down and it is not worthwhile to continue to repair.
                      Regardless of the circumstances, you can still surrender
                      the property back to the mortgage company or to the
                      lienholder. If you do so, the balance of the debt will be
                      wiped out (discharged).
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      What if there is a co-signer on a debt of mine?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      If there is a co-signer on a credit card, medical bill,
                      personal loan, or other such bill, then the co-signer will
                      be responsible for paying that bill once you have
                      discharged your debt. This is also the case if there is a
                      co-signer on a home mortgage or car note and you are
                      surrendering the home or car. If, on the other hand, you
                      are keeping the home or car, and you reaffirm the debt,
                      then both you and the co-signer will remain obligated to
                      pay the debt reaffirmed.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const query = graphql`
  query MyQuery {
    business: file(relativePath: { eq: "samson-ZGjbiukp_-A-unsplash.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    personal: file(relativePath: { eq: "bankruptcy.org.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
